.hero{
    background-color: #ffffff;
    /* padding-top:5%; */

}

#hello{
    text-align: left;
}

#deeps{
    font-size: 50px;
}
img{
    width:90%;
}
#download{
    background-color: white;
    color:#6ead3a;

}
#download:hover{
    background-color:#6ead3a;
    color:white;
    border: #6ead3a;
    /* outline: #6ead3a !important;  */
}
.cont{
    margin: 10px;
}