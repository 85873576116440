.left{
    text-align: right;
    /* background-color: #3f3d56; */
}

.right{
    text-align: left;
}
li{
    font-size: large;
}
/* .onlyHead{
    color:#3f3d56;
}
.onlyHead:hover{
    background-color: white;
} */
.proj-box{
    color:#6ead3a;
    margin:2%;
    cursor: pointer;
}
.proj-box:hover{
    background-color: #6ead3a;
    color:white;
}
h3{
    color:#3f3d56;
}
a{
    text-decoration: none;
    color:#6ead3a;
}
a:hover{
    color:white;
}