#main {
    padding-top: 5% ;
    display: flex;
    align-items: center;
    height: 100%;
}

h1 {
    color:#222022;
}

/* .tech-box{

    color:#6ead3a;
 
    margin:4%;
    height:auto;
    padding-top: 10px;
    padding-bottom: 20px;


}
.tech-box:hover{
    background-color: #6ead3a;
    color:white;
} */

/* @media screen and (min-width: 1080px) and (max-width: 1920px ) {
    #main {
      height: 580px;
 
    }
  } */