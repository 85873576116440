
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro&display=swap');

*{
  font-family: 'Readex Pro', sans-serif;
}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.tech-box{

  /* border-style: solid; */
  color:#6ead3a;
  /* border-color: #6ead3a; */
  /* outline-color: #6ead3a; */
  /* border-width: 1px;
  border-radius: 5px; */
  margin:4%;
  height:auto;
  padding-top: 10px;
  padding-bottom: 20px;


}
.tech-box:hover{
  background-color: #6ead3a;
  color:white;
}
